<template>
  <div class="humanitarian">
    <div class="human-continar">
     <!-- <Projectmap></Projectmap> -->
     <Projectactive :activelist="activelist" :changeTab="changeTab"></Projectactive>
     <!-- <Projectintroduct></Projectintroduct> -->
     <!-- <Projectnews :newslist="newslist" @childByValue="childByValue"></Projectnews> -->
    </div>
  </div>
</template>

<script>
// import Projectmap from './components/projectmap'
import Projectactive from './components/projectactive'
// import Projectintroduct from './components/projectintroduct.vue'
// import Projectnews from './components/projectnews.vue'
export default {
  name: 'Humanitarian',
  components: {
    // Projectmap,
    Projectactive,
    // Projectintroduct,
    // Projectnews
  },
  data () {
    return {
      newslist: [],
      activelist: []
    }
  },
  created () {
    // this.getnewslist()
  },
  methods : {
    changeTab(index){
      console.log(index)
    },
  }
}
</script>

<style lang="less" scoped>
.title {
    margin-top: 20px;
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 16px;
}
.humanitarian {
    background-color:  #f5f5f5;
    .human-continar {
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}
</style>
