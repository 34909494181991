<template>
	<div>
		
		<TitleMore
		:title="titleList"
		@handleMore="selfhandleMore(titleList[0],'rdxm_xmhd')"
      	@handleTab="handleTab"
		></TitleMore>
		<div class="projectactive">
			<div class="projectcontent">
				<div class="activebanners">
					<div class="block" >
						<el-carousel trigger="click" height="480px" >
							<el-carousel-item v-for="item in imgList" :key="item.id" >
								<!-- <h3 class="small">{{ item }}</h3> -->
								<img style="width:100%;height:100%" @click="$goto('/detailShow', { id: item.id })" :src="item.smallImage" alt="" />
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="mask" ></div>
				</div>
				<div class="activenews">
					<ul>
						<li v-for="(item) in newsList" :key="item.id" @click="$goto('/detailShow', { id: item.id })">
							<p>
								<i>{{ item.title }}</i>
							</p>
							<span>{{ item.publishDate | formateData }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div style="margin-top:50px"></div>
		<TitleMore
		:title="['项目公示']"
		@handleMore="selfhandleMore('项目公示','rdxm_xmzjbg')"
      	@handleTab="handleTab"
		></TitleMore>
		<!-- <div class="projectactive">
			<div class="projectcontent">
				<div class="activebanners">
					<div class="block" >
						<el-carousel trigger="click" height="480px" >
							<el-carousel-item v-for="item in imgList1" :key="item.id" >
								<img style="width:100%;height:100%" @click="$goto('/detailShow', { id: item.id })" :src="item.smallImage" alt="" />
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="mask" ></div>
				</div>
				<div class="activenews">
					<ul>
						<li v-for="(item) in newsList1" :key="item.id" @click="$goto('/detailShow', { id: item.id })">
							<p>
								<i>{{ item.title }}</i>
							</p>
							<span>{{ item.publishDate | formateData }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div> -->
		<div class="baseCard">
      <div
        class="itemText"
		    style="margin-bottom: 10px;"
        v-for="item in activelist1"
        :key="item.id"
        @click="$goto('/detailShow',{id:item.id})"
      >
        <p>
          <img
            src="../../../assets/img/list-little.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
        </p>
        <p class="itemTextTime">{{item.publishDate}}</p>
      </div>
    </div>
	</div>
</template>

<script>
import TitleMore from "@components/TitleMore.vue";
import apiUrls from '@api'
export default {
	components: {
		TitleMore
	},
	name: 'Projectactive',
  mounted() {
	  this.getactivelist('rdxm_xmhd')
	  this.getactivelist1('rdxm_xmzjbg')
  },
  data () {
      return {
          imgList:[],
          newsList:[],
          imgList1:[],
          newsList1:[],
		  activelist:[],
		  activelist1:[],
		  titleList:['项目动态']
      }
    },
	methods: {
		selfhandleMore(title,node) {
			if(node == 'rdxm_xmzjbg'){
				this.$router.push({
					path: '/infoopen',
					query: { currentIndex: 6 }
				});
			}else{
				this.$router.push({
					path: '/imgDetail',
					query: { name: title, type: node }
				});
			}
		},
		handleTab(index){
			switch (index) {
				case 0:
					this.getactivelist('rdxm_xmhd')
					break;
				case 1:
					this.getactivelist('xmhd_sxhhzxd')
					break;
				case 2:
					this.getactivelist('xmhd_jczxd')
					break;
			}
		},
		getactivelist (type) {
			apiUrls.gethumanitarian({node: type}).then(res=>{
				this.activelist= res.results.data;
				this.setData()
			})
		},
		getactivelist1 (type) {
			// apiUrls.gethumanitarian({node: type}).then(res=>{
			// 	this.activelist1= res.results.data;
			// 	this.setData1()
			// })
			apiUrls.getinfoopen({node: 'xxgk_xmgs'}).then(res=>{
				this.activelist1= res.results.data
			})
		},
		setData(){
			this.$nextTick(function() {
				let _this = this;
				_this.newsList = [];
				_this.imgList = [];
				this.activelist.forEach((item,ind) => {
					//显示所有的新闻
					_this.newsList.push(item)
					if(_this.imgList.length<3&&item.smallImage){
						_this.imgList.push(item)
					}
					//else{
					//     _this.newsList.push(item)
					// }
					
				})
			});
        },
		setData1(){
			this.$nextTick(function() {
				let _this = this;
				_this.newsList1 = [];
				_this.imgList1 = [];
				this.activelist1.forEach((item,ind) => {
					//显示所有的新闻
					_this.newsList1.push(item)
					if(_this.imgList1.length<3&&item.smallImage){
						_this.imgList1.push(item)
					}
					//else{
					//     _this.newsList.push(item)
					// }
					
				})
			});
			console.log(this.newsList)
        }
	},
};
</script>

<style lang="less" scoped>
.projectactive {
	box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
}
.title {
	margin-top: 53px;
	font-size: 22px;
	color: #333333;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	margin-bottom: 16px;
	span {
		&:nth-child(1) {
			display: flex;
			&::before {
				content: '';
				display: block;
				height: 20px;
				width: 20px;
				background: url('../../../assets/img/title.png') no-repeat center center;
			}
		}
		&:nth-child(2) {
			color: #ababab;
			font-size: 17px;
		}
	}
}
.projectcontent {
	padding: 20px;
	// box-sizing: border-box;
	height: 480px;
	display: flex;
	background-color: #ffffff;
	.activebanners {
		
		position: relative;
		width: 646px;
		height: 480px;
		background-color: skyblue;
		.el-carousel__item h3 {
			color: #475669;
			font-size: 14px;
			opacity: 0.75;
			line-height: 150px;
			margin: 0;
		}

		.el-carousel__item:nth-child(2n) {
			background-color: #99a9bf;
		}

		.el-carousel__item:nth-child(2n + 1) {
			background-color: #d3dce6;
		}
		.mask {
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 645px;
			height: 40px;
			// background: #000000;
			opacity: 0.35;
			z-index: 2;
		}
	}
	.activenews {
		flex: 1;
		//    background-color: pink;
		padding: 0 23px;
		li {
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			
			padding: 10px 0;
			margin-bottom: 10px;
			p {
				display: flex;
				color: #333333;
				&:hover {
					color: #d1292e;
				}
				font-size: 16px;
				&::before {
					content: '';
					display: block;
					width: 16px;
					height: 16px;
					background: url('../../../assets/img/list-little.png') no-repeat center center;
				}
				i {
					display: inline-block;
					width: 331px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			span {
				color: #ababab;
				font-size: 16px;
			}
		}
	}
}
</style>
