<template>
  <div class="titleMore">
    <p class="titleMoreTitle"><span
        class="tmTitle"
        :class="[activeIndex!=index?'activeStyle':'']"
        v-for="(item,index) in title"
        :key="index"
        @click="handleTab(index)"
      >
        <img
          style="margin-right:10px"
          v-show="activeIndex==index"
          src="../assets/img/title.png"
          alt=""
        >
        {{item}}</span>
    </p>
    <p
      :class="[isDonate?'donateSpan':'moreSpan']"
      v-show="isMore"
      @click="handleMore"
    >{{moreTitle}}</p>
  </div>
</template>

<script>
import { type } from "os";
export default {
  data() {
    return {
      activeIndex: 0,
    };
  },
  props: {
    isMore: {
      type: Boolean,
      default: true,
    },
    isTab: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Array,
      default: [],
    },
    moreTitle: {
      type: String,
      default: ">>更多",
    },
    isDonate: {
      type: Boolean,
      default: false,
    },
    // activeIndex: {
    //   type: Number,
    //   default: 0,
    // },
  },
  computed: {
    // activeIndex: {
    //   get: function () {
    //     return this.activeIndex;
    //   },
    //   set: function (val) {},
    // },
  },

  components: {},

  mounted() {
		console.log(this.title);
	},

  methods: {
    handleMore() {
      this.$emit("handleMore", this.activeIndex);
    },
    handleTab(index) {
      this.activeIndex = index;
      this.$emit("handleTab", index);
    },
  },
};
</script>
<style lang="less" scoped>
.titleMore {
  width: 1200px;
  max-width: 1200px;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .titleMoreTitle {
    display: flex;
    align-items: center;
  }
  .tmTitle {
    color: #333333;
    font-size: 22px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 60px;
    cursor: pointer;
  }
  .moreSpan {
    color: #ababab;
    font-size: 18px;
    cursor: pointer;
  }
  .donateSpan {
    color: red;
    text-decoration: underline;
    cursor: pointer;
  }
  .activeStyle {
    color: #999999;
  }
}
</style>